<ngx-spinner bdColor="rgba(51,51,51,0.8)"
             size="medium"
             color="#fff"
             type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div class="row" style="padding: 50px 0 30px;">
  <div class="col-12 col-md-6">
    <img style="width:100%" src="../../assets/market-insights-logo.png" alt="...market-insights-logo">
  </div>
  <div class="col-12 col-md-5 offset-md-1" *ngIf="brokerDetails">
    <app-broker-signature [brokerDetails]="brokerDetails"></app-broker-signature>
  </div>
</div>

<hr style=" border-top: 2px solid rgba(0,0,0,.2); " />

<div class="row" style="margin:35px 0;" *ngIf="isError">
  <h4>Sorry, no data available for requested report.</h4>
</div>
<div class="row" style="margin:35px 0;" *ngIf="isHighlights">
  <div class="col-12 col-md-6">
    <google-map height="500px"
                width="100%"
                [center]="center"
                (idle)="setBounds()" [options]="options">
      <map-polygon *ngFor="let polygon of vertices"
                   [paths]="polygon" [options]="StyleOptions"></map-polygon>
    </google-map>
  </div>
  <div class="col-12 col-md-6" style="text-align: center; font-weight: bold;">
    <h1 style="text-align:center; margin-top:60px; font-weight:bold;">"{{highlights.name}}"</h1>
    <h2 style="text-align:center; margin-top:15px;">{{highlights.recapDate}} Recap</h2>
    <div class="col-12 col-md-3" style="display: inline-block; margin-top:35px;">
      <h4 style="word-spacing: 100vw; margin-bottom:15px;">{{highlights.newStatus}}</h4>
      <hr style="border-top: 5px solid rgb(15, 82, 186); width: 50%; margin: auto; opacity: 1;" />
      <h1 style="font-weight: bold; font-size: 3rem; margin-top: 10px;">{{highlights.newListingsCount}}</h1>
    </div>
    <div class="col-12 col-md-3" style="display: inline-block; margin-top:35px;">
      <h4 style="word-spacing: 100vw; margin-bottom:15px;">{{highlights.pendingStatus}}</h4>
      <hr style="border-top: 5px solid #FFA500; width: 50%; margin: auto; opacity:1;" />
      <h1 style="font-weight: bold; font-size: 3rem; margin-top: 10px;">{{highlights.pendingCount}}</h1>
    </div>
    <div class="col-12 col-md-3" style="display: inline-block; margin-top:35px;">
      <h4 style="word-spacing: 100vw; margin-bottom:15px;">{{highlights.activeStatus}}</h4>
      <hr style="border-top: 5px solid rgba(167, 199, 231, 0.6); width: 50%; margin: auto; opacity: 1;" />
      <h1 style="font-weight: bold; font-size: 3rem; margin-top: 10px;">{{highlights.activeInventory}}</h1>
    </div>
    <div class="col-12 col-md-3" style="display: inline-block; margin-top:35px;">
      <h4 style="word-spacing: 100vw; margin-bottom:15px;">{{highlights.soldStatus}}</h4>
      <hr style="border-top: 5px solid #CD2128; width: 50%; margin: auto; opacity: 1;" />
      <h1 style="font-weight: bold; font-size: 3rem; margin-top: 10px;">{{highlights.soldCount}}</h1>
    </div>
  </div>
</div>

<hr *ngIf="isHighlights" style=" border-top: 2px solid rgba(0,0,0,.2); " />

<div class="row" style="margin-top:35px;" *ngIf="isChartData">
  
  <div class="col-12">
    <ng-template #list>
      <div class="d-flex flex-column">
        <p><b>New listings: </b>Number of homes listed during the month </p>
        <p><b>Pending: </b>Number of homes reported as going under contract during the month</p>
        <p><b>Sold: </b>Number of homes sold during the month</p>
        <p><b>Active Inventory: </b>Number of homes active on the last day of the month</p>
      </div>
    </ng-template>
    <h3 style="font-weight:bold">
      Monthly Market Trends <span style="display: inline-block; padding-left: 10px; cursor: pointer;" [ngbTooltip]="list" triggers="click"
			[autoClose]="true" placement="end"><i class="fa fa-info" style="font-size: 18px; vertical-align: middle; border: 2px solid; padding: 1px 6px; border-radius: 50%;" aria-hidden="true"></i></span>
    </h3>
    <canvas id="canvas" style="height:450px !important; max-height:450px; margin-top:20px;" *ngIf="isChartData">
      {{chart}}
    </canvas>
  </div>
</div>
<br />

<div class="row" *ngIf="isActiveData">
  <div class="col-12">
    <h3 style="font-weight:bold;">Active<span style="float:right; font-size:20px;"><a style="color: #000;" href="{{viewAllUrl}}?status=active">View All Active</a></span></h3>
  </div>
</div>

<div class="row" style="margin-top:20px" *ngIf="isActiveData">
  <div class="col-md-4" *ngFor="let active of activeData;">
    <div class="card" style=" border: none;">
      <a href="/listing/{{active.id}}" target="_blank">
        <img style="height: 225px;" src="{{active.mainPhoto?.thumbnail}}" class="card-img-top" alt="...">
      </a>
      <div class="card-body" style="padding-left: 0; padding-right: 0; font-size: 18px;">
        <p style="font-weight: bold; margin-bottom:0;"><span style="color: rgb(58 138 218);">{{active.status}}</span><span style="float: right; ">{{active.listPrice | currency:'USD' : 'symbol' : '2.0'}}</span></p>
        <p style="margin-bottom: 0;">{{active.address?.street}}</p>
        <p *ngIf="active.isNew"><span style="font-weight: bold;">New</span> {{active.listDate | date: "MM/dd/yyyy"}}</p>
        <p *ngIf="!active.isNew">Listed {{active.listDate | date: "MM/dd/yyyy"}}</p>
      </div>
    </div>
  </div>
</div>

<hr *ngIf="isActiveData" style=" border-top: 2px solid rgba(0,0,0,.2); " />

<div class="row" *ngIf="isPendingData">
  <div class="col-12">
    <h3><span style="font-weight:bold;">Pending</span><span> | Reported Under Contract</span><span style="float:right; font-size:20px;"><a style="color: #000;" href="{{viewAllUrl}}?status=pending">View All Pending</a></span></h3>
  </div>
</div>

<div class="row" style="margin-top:20px" *ngIf="isPendingData">
  <div class="col-md-4" *ngFor="let pending of pendingData;">
    <div class="card" style=" border: none;">
      <a href="/listing/{{pending.id}}" target="_blank">
        <img style="height: 225px;" src="{{pending.mainPhoto?.thumbnail}}" class="card-img-top" alt="...">
      </a>
      <div class="card-body" style="padding-left: 0; padding-right: 0; font-size: 18px;">
        <p style="font-weight: bold; margin-bottom:0;"><span style="color: #FFA500 ">{{pending.status}}</span><span style="float: right; ">{{pending.price | currency:'USD' : 'symbol' : '2.0'}}</span></p>
        <p style="margin-bottom: 0;">{{pending.address?.street}}</p>
        <p>Listed {{pending.listDate | date: "MM/dd/yyyy"}}</p>
      </div>
    </div>
  </div>
</div>

<hr *ngIf="isPendingData" style=" border-top: 2px solid rgba(0,0,0,.2); " />

<div class="row" *ngIf="isSoldData">
  <div class="col-12">
    <h3><span style="font-weight:bold;">Sold Last Month</span><span> | Property Transferred</span><span style="float:right; font-size:20px;"><a style="color: #000;" href="{{viewAllUrl}}?status=sold">View All Sold</a></span></h3>
  </div>
</div>

<div class="row" style="margin-top:20px" *ngIf="isSoldData">
  <div class="col-md-4" *ngFor="let sold of soldData;">
    <div class="card" style=" border: none;">
      <a href="/listing/{{sold.id}}" target="_blank">
        <img style="height: 225px;" src="{{sold.mainPhoto?.thumbnail}}" class="card-img-top" alt="...">
      </a>
      <div class="card-body" style="padding-left: 0; padding-right: 0; font-size: 18px;">
        <p style="margin-bottom:0;"><span style="color: #c9302c; font-weight: bold;">{{sold.status}}</span><span> {{sold.soldData?.soldDate | date: "MM/dd/yyyy"}}</span><span style="float: right; font-weight: bold;">{{sold.soldData?.soldPrice | currency:'USD' : 'symbol' : '2.0'}}</span></p>
        <p>{{sold.address?.street}}</p>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="isSoldData">
  <div class="col-12">
    <table class="table table-striped">
      <thead>
        <tr style="text-align:right;">
          <th style="text-align: start">Street Address</th>
          <th>Closed Date</th>
          <th>Beds</th>
          <th>Baths</th>
          <th>SqFt</th>
          <th>Lot (Ac.)</th>
          <th>List Price</th>
          <th>Sale Price</th>
          <th>Variance</th>
          <th>DOM*</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sold of soldData;" style="text-align:right;">
          <td style="text-align:start"><a target="_blank" href="/listing/{{sold.id}}">{{sold.address?.street}}</a></td>
          <td>{{sold.soldData?.soldDate | date: "MM/dd/yyyy"}}</td>
          <td>{{sold.beds}}</td>
          <td>{{sold.baths}}</td>
          <td>{{sold.sqft | number}}</td>
          <td>{{sold.acreage | number:'1.0-1'}}</td>
          <td>{{sold.listPrice | currency:'USD' : 'symbol' : '2.0'}}</td>
          <td>{{sold.soldData?.soldPrice  | currency:'USD' : 'symbol' : '2.0'}}</td>
          <td>{{sold.variance | number : '1.0-2'}}%</td>
          <td>{{sold.dom}}</td>
        </tr>
      </tbody>
    </table>
    <p style="font-style: italic; padding-left:15px; margin-bottom:20px;">
      *Days on Market is a reflection of the number of days a property has been available for sale.
    </p>
  </div>
</div>

<hr *ngIf="isSoldData" style=" border-top: 2px solid rgba(0,0,0,.2); " />

<div class="row" *ngIf="isCommunityVideo">
  <div class="col-12">
    <h3><span style="font-weight:bold;">Nearby Communities</span><span> | Videos</span><span style="float:right; font-size:20px;"><a style="color: #000;" href="/community-spotlight-videos">View Available Community Videos</a></span></h3>
  </div>
</div>

<div class="row" *ngIf="isCommunityVideo">
  <div class="col-lg-4 col-md-12" *ngFor="let video of communityVideosData;">
    <div class="ratio ratio-4x3">
      <iframe [src]="sanitizeImageUrl(video.vimeoUrl)" height="225" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </div>
  </div>
</div>
<hr *ngIf="isCommunityVideo" style=" border-top: 2px solid rgba(0,0,0,.2); " />
<div class="row" style="padding-bottom: 20px">
  <div class="col-12">
    <p style="float: right;" *ngIf="isHighlights">
      * All listings from all companies are available within supported MLS areas.
    </p>
  </div>
</div>
<script type="text/javascript" src="https://jlscott.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/tod1zk/b/5/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=7ed2f613"></script>

