<table>
  <tr>
    <td align="center" style="font-family: sans-serif; font-size:16px;">
      <table>
        <tr *ngIf="brokerDetails">
          <td valign="top" style="padding: 0 30px 0 0; vertical-align:top; "><img src="{{brokerDetails?.imageUrl}}" alt="FullName - Photo" width="110" height="110" /></td>
          <td style="line-height:1.3">
            <b style="font-size: 18px">{{brokerDetails?.name}}</b><br />
            {{brokerDetails?.title}}<br />
            <span *ngIf="brokerDetails.primaryPhone">
              <a style="text-decoration: none; color: #006341;" href="{{brokerDetails?.primaryPhone.number}}" alt="Primary Phone">{{brokerDetails?.primaryPhone.number}}</a><br />
            </span>
            <a href="mailto:{{brokerDetails?.alias}}@johnlscott.com" style="text-decoration: none; color: #006341">{{brokerDetails?.alias}}@johnlscott.com</a><br />
            <a href="http://{{brokerDetails?.alias}}.johnlscott.com" style="text-decoration: none; color: #006341">{{brokerDetails?.alias}}.johnlscott.com</a><br />
            <span *ngIf="brokerDetails.socialMedia">
              <a *ngIf="brokerDetails.socialMedia.facebookUrl" href="{{brokerDetails?.socialMedia.facebookUrl}}" style="display: inline-block; padding-right: 6px; line-height: 2.8">
                <img src="https://assets.jlscontent.com/images/email/social-icons/facebook_ic.png" width="12" height="20" />
              </a>
              <a *ngIf="brokerDetails.socialMedia.linkedInUrl" href="{{brokerDetails?.socialMedia.linkedInUrl}}" style="display: inline-block; padding-right: 6px">
                <img src="https://assets.jlscontent.com/images/email/social-icons/linkedin_ic.png" width="21" height="20" />
              </a>
              <a *ngIf="brokerDetails.socialMedia.twitterUrl" href="{{brokerDetails?.socialMedia.twitterUrl}}" style="display: inline-block; padding-right: 6px">
                <img src="https://assets.jlscontent.com/images/email/social-icons/twitter_ic.png" width="25" height="20" />
              </a>
              <a *ngIf="brokerDetails.socialMedia.instagramUrl" href="{{brokerDetails?.socialMedia.instagramUrl}}" style="display: inline-block; padding-right: 6px">
                <img src="https://assets.jlscontent.com/images/email/social-icons/instagram_ic.png" width="20" height="20" />
              </a>
              <a *ngIf="brokerDetails.socialMedia.tumblrUrl" href="{{brokerDetails?.socialMedia.tumblrUrl}}" style="display: inline-block; padding-right: 6px">
                <img src="https://assets.jlscontent.com/images/email/social-icons/tumblr_ic.png" width="14" height="20" />
              </a>
              <a *ngIf="brokerDetails.socialMedia.youTubeUrl" href="{{brokerDetails?.socialMedia.youTubeUrl}}" style="display: inline-block; padding-right: 6px">
                <img src="https://assets.jlscontent.com/images/email/social-icons/you_tube_ic.png" width="29" height="20" />
              </a>
            </span>
            <br />
            <button type="button" (click)="openFormModal()" class="btn btn-dark" style="margin-top:15px">
              Send a Message
            </button>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>

<div class="modal fade"
     id="myModal"
     tabindex="-1"
     aria-labelledby="contactModal"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="contactModal">Contact John L. Scott</h5>
        <button type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="header col-12">
            <div id="form-description" data-bind="text: formMetadata.description">Please complete the following form and submit your request.</div>
          </div>
          <div class="col-12">
            <form [formGroup]="contactForm" (ngSubmit)="onFormSubmit()">
              <div class="form-group" style="margin-top:25px;">
                <input name="Name" formControlName="name" class="form-control" type="text" tabindex="10" placeholder="Your name" required="">
              </div>
              <div class="form-group" style="margin-top:15px;">
                <input id="Phone" name="Phone" formControlName="phone" class="form-control" tabindex="20" type="tel" style="width: 100%;" placeholder="Your phone" validationmessage="Please provide a valid Phone number to receive SMS (text message)." autocomplete="off">
                <input id="jls-sms-checkbox" style="width:20px; height:20px; margin-top: 10px; margin-right:10px; cursor:pointer" formControlName="sendSms" type="checkbox"><label for="jls-sms-checkbox"></label><span>Send Text (SMS)?</span>
                <span class="k-invalid-msg" tabindex="30" data-for="Phone"></span>
              </div>
              <div class="form-group" style="margin-top:15px;">
                <input name="Email" formControlName="email" class="form-control" tabindex="40" type="email" placeholder="Your email" data-email-msg="Email format is not valid" validationmessage="Please provide either a Phone number or an Email address">
              </div>
              <div class="form-group" style="margin:15px 0;">
                <textarea name="Message" formControlName="msg" class="form-control" tabindex="50" placeholder="Your message (optional)"></textarea>
              </div>
              <button style="float: right;" class="btn btn-dark" type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

