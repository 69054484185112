import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { ChildRoutingModule } from './child-routing.module';
import { AppComponent } from './app.component';
import { NgChartsModule } from 'ng2-charts';
import { HomeComponent } from './home/home.component';
import { BrokerSignatureComponent } from './broker-signature/broker-signature.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { FooterComponent } from './footer/footer.component';
import { SellerReportComponent } from './seller-report/seller-report.component';
import { NgxPaginationModule } from 'ngx-pagination';

import { GoogleMapsModule } from '@angular/google-maps';
import { DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MarketInsightsReportComponent } from './market-insights-report/market-insights-report.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BrokerSignatureComponent,
    NavMenuComponent,
    FooterComponent,
    SellerReportComponent,
    MarketInsightsReportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ChildRoutingModule,
    NgChartsModule,
    HttpClientModule,
    FormsModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgxPaginationModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
