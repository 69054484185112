import { Injectable } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Highlights, Listings } from './market-insights.model';

@Injectable({
  providedIn: 'root'
})
export class MarketInsightsService {
  brokerDetailsResponse: any;
  chartDataResponse: any;
 
  constructor(private httpClient: HttpClient) {
  }

  private getHighlightsUrl = 'marketinsights/savedsearch/highlights/';
  private getChartDataUrl = 'marketinsights/savedsearch/trends/';
  private createInfoRequestUrl = 'message/information-request';
  private activeUrl = 'marketinsights/savedsearch/listings/active/';
  private pendingUrl = 'marketinsights/savedsearch/listings/pending/';
  private soldUrl = 'marketinsights/savedsearch/listings/sold/';
  private communityVideosUrl = 'marketinsights/savedsearch/community-videos/';
  private getSiteMapUrl = 'site/sitemap?domain=';

  getHighlights(apiSiteUrl: string, savedSearchId: number): Observable<Highlights> {
    return this.httpClient.get<Highlights>(`${apiSiteUrl}${this.getHighlightsUrl}${savedSearchId}`);
  }

  getChartData(apiSiteUrl: string, savedSearchId: number): Observable<any> {
    return this.httpClient.get<any>(`${apiSiteUrl}${this.getChartDataUrl}${savedSearchId}`);
  }

  getChartDataResponse() {
    return this.chartDataResponse;
  }

  setChartDataResponse(chartResponse: any) {
    this.chartDataResponse = chartResponse;
  }

  getActiveData(apiSiteUrl: string, savedSearchId: number): Observable<any> {
    return this.httpClient.get<any>(`${apiSiteUrl}${this.activeUrl}${savedSearchId}`);
  }

  getPendingData(apiSiteUrl: string, savedSearchId: number): Observable<any> {
    return this.httpClient.get<any>(`${apiSiteUrl}${this.pendingUrl}${savedSearchId}`);
  }

  getSoldData(apiSiteUrl: string, savedSearchId: number): Observable<any> {
    return this.httpClient.get<any>(`${apiSiteUrl}${this.soldUrl}${savedSearchId}`);
  }

  getCommunityVideosData(apiSiteUrl: string, savedSearchId: number): Observable<any> {
    return this.httpClient.get<any>(`${apiSiteUrl}${this.communityVideosUrl}${savedSearchId}`);
  }

  getSitemapData(apiSiteUrl: string, domain: string): Observable<any> {
    return this.httpClient.get<any>(`${apiSiteUrl}${this.getSiteMapUrl}${domain}`);
  }
}
