import { Component, OnInit, ViewChild } from '@angular/core';
import { Highlights, Listings, Address, CommunityVideos } from './market-insights.model';
import { MarketInsightsService } from './market-insights.service';
import { CommonService } from './../common.service';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

import { NgxSpinnerService } from 'ngx-spinner';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'app-market-insights-report',
  templateUrl: './market-insights-report.component.html',
  styleUrls: ['./market-insights-report.component.css']
})

export class MarketInsightsReportComponent implements OnInit {
  brokerDetails: any;
  savedSearchId!: number;
  chartData: any;
  active: any;
  new: any;
  pending: any;
  sold: any;
  date: any;
  chart: any = [];
  labelMonth: any;
  activeData?: Listings[] = [];
  pendingData?: Listings[] = [];
  soldData?: Listings[] = [];
  address?: Address = new Address;
  communityVideosData?: CommunityVideos[] = [];
  highlights: Highlights = new Highlights;
  viewAllUrl!: string;
  triangleCoords: any;
  urlSafe!: SafeResourceUrl;
  isCommunityVideo!: boolean;
  isActiveData!: boolean;
  isPendingData!: boolean;
  isSoldData!: boolean;
  isChartData: boolean = true;
  isHighlights!: boolean;
  isError: boolean = false;
  publicUrl!: string;
  doneCommunityVideo: boolean = false;
  doneActiveData: boolean = false;
  donePendingData: boolean = false;
  doneSoldData: boolean = false;
  doneChartData: boolean = false;
  doneHighlights: boolean = false;
  sw!: any;
  ne!: any;

  constructor(private marketInsightsService: MarketInsightsService, private commonService: CommonService, public datepipe: DatePipe, private router: ActivatedRoute, public sanitizer: DomSanitizer, private spinner: NgxSpinnerService) {
  }
  ngOnInit() {
    this.spinner.show();
    this.savedSearchId = this.router.snapshot.params['id'];
    this.getPublicUrl();
    this.viewAllUrl = "/search/saved/" + this.savedSearchId;
  }
  @ViewChild(GoogleMap, { static: false })
  map!: GoogleMap;

  center!: google.maps.LatLngLiteral;
  bounds = new google.maps.LatLngBounds;
  zoom!: any;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    disableDefaultUI: true,
    clickableIcons: false,
    gestureHandling: 'auto',
    draggable: false,
    keyboardShortcuts: false,
  };
  vertices: google.maps.LatLngLiteral[][] = [];
  StyleOptions: google.maps.PolygonOptions = {
    strokeColor: '#b4b5b7',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: '#006341',
    fillOpacity: 0.1,
    clickable: false
  }

  getPublicUrl() {
    this.commonService.getPublicSiteLink().subscribe((data) => {
      this.publicUrl = data.url;
      if (this.publicUrl) {
        this.getBrokerDetails();
        this.getHighlights();
        this.getChartDetails();
        this.getActiveListingsData();
        this.getPendingListingsData();
        this.getSoldListingsData();
        this.getCommunityVideosData();
      }
    })
  }

  getBrokerDetails() {
    this.commonService.getBrokerDetails(this.publicUrl).subscribe((data) => {
      this.brokerDetails = data;
      this.commonService.setBrokerDetailsResponse(this.brokerDetails);
    });
  }

  checkDoneAll() {
    if (!this.doneActiveData) return;
    if (!this.doneChartData) return;
    if (!this.doneCommunityVideo) return;
    if (!this.doneHighlights) return;
    if (!this.donePendingData) return;
    if (!this.doneSoldData) return;
    this.spinner.hide();
  }

  getHighlights() {
    this.marketInsightsService.getHighlights(this.publicUrl, this.savedSearchId).subscribe((response) => {
      this.highlights = response;
      this.doneHighlights = true;
      this.checkDoneAll();
      if (!this.highlights) {
        return;
      }
      this.triangleCoords = this.highlights.polygonPoints;
      this.vertices = this.triangleCoords;
      this.center = this.highlights.mapCenter;
      this.sw = this.highlights.southWest;
      this.ne = this.highlights.northEast;
      this.bounds = new google.maps.LatLngBounds(this.sw, this.ne);
      this.zoom = this.highlights.zoomLevel;
      this.highlights.recapDate = this.datepipe.transform(this.highlights.recapDate, 'MMMM yyyy');
      this.highlights.reportDate = this.datepipe.transform(this.highlights.reportDate, 'MMMM yyyy');
      this.isHighlights = true;
    },
      (error) => {
        console.error(error);
        this.isError = true;
        this.isHighlights = false;
        this.doneHighlights = true;
        this.doneActiveData = true;
        this.doneChartData = true;
        this.doneCommunityVideo = true;
        this.donePendingData = true;
        this.doneSoldData = true;
        this.checkDoneAll();

    });
  }

  setBounds() {
    this.map.fitBounds(this.bounds);
  }

  getChartDetails() {
    this.marketInsightsService.getChartData(this.publicUrl, this.savedSearchId).subscribe((data) => {

      this.doneChartData = true;
      this.checkDoneAll();
      this.chartData = data;
      this.active = this.chartData.map((m: any) => m.active);
      this.pending = this.chartData.map((m: any) => m.pending);
      this.sold = this.chartData.map((m: any) => m.sold);
      this.new = this.chartData.map((m: any) => m.new);
      this.date = this.chartData.map((m: any) => this.datepipe.transform(m.date, 'MMM yyyy'));
      this.chart = new Chart('canvas', {
        type: 'line',
        data: {
          labels: this.date,
          datasets: [
            {
              label: 'New Listings',
              data: this.new,
              borderColor: 'rgb(15, 82, 186)',
              backgroundColor: 'rgb(15, 82, 186)',
              pointBackgroundColor: 'rgb(15, 82, 186)',
              pointBorderColor: 'rgb(15, 82, 186)',
              pointHoverBackgroundColor: 'rgb(15, 82, 186)',
              pointHoverRadius: 8,
              tension: 0.4
            },
            {
              label: 'Pending',
              data: this.pending,
              borderColor: '#FFA500',
              backgroundColor: '#FFA500',
              pointBackgroundColor: '#FFA500',
              pointHoverBackgroundColor: '#FFA500',
              pointHoverRadius: 8,
              tension: 0.4
            },
            {
              label: 'Sold',
              data: this.sold,
              borderColor: '#CD2128',
              backgroundColor: '#CD2128',
              pointBackgroundColor: '#CD2128',
              pointHoverBackgroundColor: '#CD2128',
              pointHoverRadius: 8,
              tension: 0.4
            },
            {
              type: 'bar',
              label: 'Active Inventory',
              data: this.active,
              borderColor: 'rgba(135,206,250)',
              backgroundColor: 'rgba(167, 199, 231, 0.6)',
              hoverBackgroundColor: 'rgba(167, 199, 231)'
            },


          ]
        },
        options: {
          responsive: true,
          scales: {
            yAxis: {
              ticks: {
                maxTicksLimit: 20
              }
            }
          },
          plugins: {
            legend: {
              position: 'bottom',
            }
          }
        },
      })
    },
      (error) => {
        console.error(error);
        this.isChartData = false;
        this.doneChartData = true;
        this.checkDoneAll();
      });

  }

  getActiveListingsData() {
    this.marketInsightsService.getActiveData(this.publicUrl, this.savedSearchId).subscribe(
      (response: Listings[]) => {
        this.activeData = response;
        if (this.activeData) {
          this.doneActiveData = true;
          this.checkDoneAll();
          this.isActiveData = this.activeData.length > 0;
        }
      },
      (error) => {
        console.error(error);
        this.isActiveData = false;
        this.doneActiveData = true;
        this.checkDoneAll();
      });
  }

  getPendingListingsData() {
    this.marketInsightsService.getPendingData(this.publicUrl, this.savedSearchId).subscribe((response: Listings[]) => {
      this.pendingData = response;
      if (this.pendingData) {
        this.donePendingData = true;
        this.checkDoneAll();
        this.isPendingData = this.pendingData.length > 0;
      }
    },
      (error) => {
        console.error(error);
        this.isPendingData = false;
        this.donePendingData = true;
        this.checkDoneAll();
      });
  }

  getSoldListingsData() {
    this.marketInsightsService.getSoldData(this.publicUrl, this.savedSearchId).subscribe((response: Listings[]) => {
      this.soldData = response;
      if (this.soldData) {
        this.doneSoldData = true;
        this.checkDoneAll();
        this.isSoldData = this.soldData.length > 0;
      }
    },
      (error) => {
        console.error(error);
        this.isSoldData = false;
        this.doneSoldData = true;
        this.checkDoneAll();
      });
  }

  getCommunityVideosData() {
    this.marketInsightsService.getCommunityVideosData(this.publicUrl, this.savedSearchId).subscribe((response: CommunityVideos[]) => {
      this.communityVideosData = response;
      if (this.communityVideosData) {
        this.doneCommunityVideo = true;
        this.checkDoneAll();
        this.isCommunityVideo = this.communityVideosData.length > 0;
      }
      //const makeRepeated = (arr:any, repeats:any) =>
      //  Array.from({ length: repeats }, () => arr).flat();
      //this.communityVideosData = makeRepeated(this.communityVideosData, 3);
    }
      ,
      (error) => {
        console.error(error);
        this.isCommunityVideo = false;
        this.doneCommunityVideo = true;
        this.checkDoneAll();
      });
  }

  sanitizeImageUrl(videoUrl: any): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
  }
}

