import { center, Location } from "../seller-report/seller-report.model";

export class Highlights {
  public name?: string;
  public mapCenter?: any;
  public zoomLevel?: number;
  public polygonPoints?: polygonPoints[];
  public reportDate: any;
  public recapDate: any;
  public statusCounts?: any;
  public activeInventory?: number;
  public activeStatus?: string;
  public soldStatus?: string;
  public newListingsCount?: number;
  public newStatus?: string;
  public pendingCount?: number;
  public soldCount?: number;
  public pendingStatus?: string;
  public northEast?: any;
  public southWest?: any;
  public eastLongitude?: any;
  public northLatitude?: any;
  public southLatitude?: any;
  public westLongitude?: any;
}

export class polygonPoints {
  lat: any;
  lng: any;
}

export class Listings {
  public acreage: any;
  public bankOwned?: boolean;
  public baths?: number;
  public beds?: number;
  public cDom?: number;
  public communityId: any;
  public courtesyOf: any;
  public cumulativeDaysOnMarket?: number;
  public daysOnMarket?: number;
  public dbAddTime?: string;
  public dom?: number;
  public favorite: any;
  public features: any;
  public fireplaces: any;
  public gallery?: any[];
  public garage: any;
  public garageSpaces: any;
  public hoaInfo: any;
  public iconKey?: string;
  public iconKeyOverride: any;
  public id?: number;
  public jlsAccountIds: any;
  public jlsAccounts?: any[];
  public jlsSecurityBrokerIds?: any[];
  public lastVerified: any;
  public links?: any[];
  public listDate?: string;
  public listPrice?: number;
  public listingActivity: any;
  public location?: Location;
  public lotSize?: number;
  public mainPhoto?: MainPhoto;
  public mediaCount?: number;
  public mls: any;
  public mlsListingId?: number;
  public newOnMarket?: boolean;
  public offerReviewDate: any;
  public openHouseCount: any;
  public overrideState: any;
  public parentOwners: any;
  public parkingSpaces: any;
  public pendingDate?: string;
  public price?: number;
  public primaryAccount: any;
  public privateRemarks: any;
  public propertyType?: string;
  public publicRemarks: any;
  public resourceTypeName: any;
  public schoolDistrict: any;
  public shortCode: any;
  public showAddress: any;
  public showMap: any;
  public showingInstructions: any;
  public showings: any;
  public soldData: any;
  public sqft?: number;
  public status?: string;
  public statusDate?: string;
  public statusName?: string;
  public taxes: any;
  public title?: string;
  public updateDate?: string;
  public viewDate: any;
  public yearBuilt: any;
  public zip: any;
  public zipExt: any;
  public address?: Address;
  public isNew?: boolean;
  public variance: any;
}

export class Address {
  public city?: string;
  public county?: string;
  public geoId?: string;
  public state?: string;
  public stateName?: string;
  public street?: string;
  public street2?: string;
  public unMapped?: boolean;
  public undisclosed?: boolean;
  public zip?: number;
}

export class MainPhoto {
  public index?: number;
  public isMain?: boolean;
  public target?: string;
  public thumbnail?: string;
  public title?: string;
  public type?: string;
}

export class CommunityVideos {
  public id?: number;
  public name?: string;
  public polygon: any;
  public savedSearchId?: number;
  public vimeoUrl?: string;
}
