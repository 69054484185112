import { Injectable } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InfoRequest } from './common.model';

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  brokerDetailsResponse: any;
  alias: any = location.host.substring(0, location.host.indexOf('.'));

  constructor(private httpClient: HttpClient) {
    if (!this.alias) {
      this.alias = "www";
    }
  }

  private getBrokerUrl = 'roster/' + (this.alias ? this.alias : "customerservice") + '?fallback=true';
  private createInfoRequestUrl = 'message/information-request';
  private getPublicSiteUrl = '/configuration/public-api-site';

  getBrokerDetailsResponse() {
    return this.brokerDetailsResponse;
  }

  setBrokerDetailsResponse(brokerResponse: any) {
    this.brokerDetailsResponse = brokerResponse;
  }

  getBrokerDetails(apiSiteUrl: string): Observable<any> {
    return this.httpClient.get<any>(`${apiSiteUrl}${this.getBrokerUrl}`);
  }

  createInfoRequest(apiSiteUrl: string, infoRequest: InfoRequest): Observable<InfoRequest> {
    return this.httpClient.post<InfoRequest>(`${apiSiteUrl}${this.createInfoRequestUrl}`, infoRequest)
  }

  getPublicSiteLink(): Observable<any> {
    return this.httpClient.get<any>(`${this.getPublicSiteUrl}`);
  }

}
