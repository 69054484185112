<header style=" min-height: 100%; min-width: 100%; padding-top: 65px; padding-bottom: 40px;">
  <nav class="navbar navbar-expand-xxl navbar-toggleable-xxl navbar-dark border-bottom box-shadow mb-3 fixed-top" style="background: #111; min-height: 66px;">
    <div class="container-fluid">
      <a class="navbar-brand jls-navbar-brand" href="/home">
        <span id="change-icon-bg" class="navbar-brand-accent">
          <img class="img-responsive" src="https://assets.jlscontent.com/images/corp/john-l-scott-logo.png" alt="John L. Scott Logo" style="max-width: 128px; height: auto" />
        </span>
      </a>
      <p *ngIf="showBrokerName" class="text-white" style=" float: left; margin-bottom: 0; font-size: 20px; padding-left: 65px; line-height: 20px;">{{brokerDetails?.name}}</p>
      <button class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded"
              (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-xxl-inline-flex flex-xxl-row" style="font-size:1em; font-weight:300"
           [ngClass]="{ show: isExpanded }">
        <div class="ul-text-last-padding">
          <ul class="navbar-nav flex-grow" *ngFor="let menuItem of siteMapDetails">
            <li class="nav-item" *ngIf="!menuItem.isUrlSlugConflicted && !menuItem.isDropdown && !menuItem.isExcludedFromNavigation">
              <a *ngIf="menuItem.isExternal" target="_blank" class="nav-link text-white" href="{{menuItem.redirectUrl}}">{{menuItem.shortTitle}}</a>
              <a *ngIf="menuItem.isInternal" class="nav-link text-white" href="/{{menuItem.urlSlug}}">{{menuItem.shortTitle}}</a>
            </li>
            <li ngbDropdown *ngIf="menuItem.isDropdown" class="nav-item" id="sitemap-menu">
              <a class="nav-link text-white" role="button" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>{{menuItem.shortTitle}}</a>
              <div ngbDropdownMenu="sitemap-menu">
                <div *ngFor="let ddItem of menuItem.items">
                  <a *ngIf="ddItem.isExternal" target="_blank" class="dropdown-item menu-item" ngbDropdownItem href="{{ddItem.redirectUrl}}">{{ddItem.shortTitle}}</a>
                  <a *ngIf="ddItem.isInternal && menuItem.urlSlug" class="dropdown-item menu-item" ngbDropdownItem href="/{{menuItem.urlSlug}}/{{ddItem.urlSlug}}">{{ddItem.shortTitle}}</a>
                  <a *ngIf="ddItem.isInternal && !menuItem.urlSlug" class="dropdown-item menu-item" ngbDropdownItem href="/{{ddItem.urlSlug}}">{{ddItem.shortTitle}}</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <ul class="navbar-nav" style="display: inline-block; float:right;">
          <li class="nav-item">
            <a class="nav-link text-white" href="/propertytracker">PropertyTracker<sup>&reg;</sup> <span class="signup" style="font-weight:bold">Sign Up</span> | Login</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
