import { Component, Inject, OnInit } from '@angular/core';
import { MarketInsightsService } from '../market-insights-report/market-insights.service';
import { CommonService } from './../common.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  siteMapDetails: any;
  domain: any;
  publicUrl!: string;
  brokerDetails: any;
  showBrokerName: boolean = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  constructor(private marketInsightsService: MarketInsightsService, private commonService: CommonService, @Inject(DOCUMENT) private document: any) {
  }

  ngOnInit() {
    this.domain = this.document.location.hostname;
    console.log(this.domain);
    this.getPublicUrl();
  }

  getPublicUrl() {
    this.commonService.getPublicSiteLink().subscribe((data) => {
      this.publicUrl = data.url;
      if (this.publicUrl) {
        this.getBrokerDetails();
        this.getSiteMapDetails();
        if (this.domain != ('localhost' || 'www.jlscloud.' || 'www.johnlscott.')) {
          this.document.getElementById("change-icon-bg").style.background = 'none';
          this.showBrokerName = true;
        }
      }
    })
  }

  getSiteMapDetails() {
    this.marketInsightsService.getSitemapData(this.publicUrl, this.domain).subscribe((data) => {
      this.siteMapDetails = data;
      console.log(this.siteMapDetails);
    });
  }

  getBrokerDetails() {
    this.commonService.getBrokerDetails(this.publicUrl).subscribe((data) => {
      this.brokerDetails = data;
      this.commonService.setBrokerDetailsResponse(this.brokerDetails);
      console.log('chay' + this.brokerDetails);
    });
  }
}
