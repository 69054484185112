import { Injectable } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Highlights, MapListings, Location } from './seller-report.model'

@Injectable({
  providedIn: 'root'
})
export class SellerReportService {

  constructor(private httpClient: HttpClient) { }

  private getPublicSiteUrl = '/configuration/public-api-site';
  private getMapRadiusUrl = 'listings/sold-by-radius/';
  private getHighlightsUrl = 'seller-report/highlights/';
  private getTrendsUrl = 'seller-report/trends/';
  private getListingUrl = 'listings/detail/';

  getPublicSiteLink(): Observable<any> {
    return this.httpClient.get<any>(`${this.getPublicSiteUrl}`);
  }

  getMapRadius(apiSiteUrl: string, location?: Location): Observable<any> {
    return this.httpClient.get<MapListings>(`${apiSiteUrl}${this.getMapRadiusUrl}?lat=${location?.latitude}&lng=${location?.longitude}`);
  }

  getHighlights(apiSiteUrl: string, listingId: number): Observable<Highlights> {
    return this.httpClient.get<Highlights>(`${apiSiteUrl}${this.getHighlightsUrl}${listingId}`);
  }

  getTrends(apiSiteUrl: string, listingId: number): Observable<any> {
    return this.httpClient.get<any>(`${apiSiteUrl}${this.getTrendsUrl}${listingId}`);
  }

  getListing(apiSiteUrl: string, listingId: number): Observable<any> {
    return this.httpClient.get<any>(`${apiSiteUrl}${this.getListingUrl}${listingId}`);
  }
}
