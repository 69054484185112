<footer class="jls-footer">
  <div class="row" style="margin:0">
    <div class="col-2 footer-medal" style="padding-right: 5px;">
      <a href="https://www.facebook.com/johnlscott" target="_blank" rel="noopener" alt="John L. Scott on Facebook" style="text-align: center; font-size: 17px; text-align: center; padding: 0 5px" role="button" aria-label="Facebook">
        <i class="fa fa-lg fa-facebook" style="color: #6fa995; display: inline-block"></i>
      </a>
      <a href="https://www.linkedin.com/company/john-l-scott-real-estate" target="_blank" rel="noopener" alt="John L. Scott on Linkedin" style="text-align: center; font-size: 20px; padding: 0 5px; text-align: center" role="button" aria-label="Linkedin">
        <i class="fa fa-lg fa-linkedin" style="color: #6fa995; display: inline-block"></i>
      </a>
      <a href="https://www.instagram.com/johnlscottnews" target="_blank" rel="noopener" alt="John L. Scott on Instagram" style="text-align: center; font-size: 19px; padding: 0 5px; text-align: center" role="button" aria-label="Instagram">
        <i class="fa fa-lg fa-instagram" style="color: #6fa995; display: inline-block"></i>
      </a>
    </div>
    <div class="text-left footer-content col-8">
      <div style="height: 34px; display: table-cell; vertical-align: middle;">
        <span style="display: inline;">© {{currentYear}} John L. Scott. All rights reserved. Some John L. Scott offices are independently owned and operated.&nbsp;|&nbsp;</span>
        <a href="/privacy-policy" style="white-space: nowrap;">Privacy Policy</a>&nbsp;|&nbsp;
        <a href="/dmca-notice" style="white-space: nowrap;">DMCA Notice</a>&nbsp;&nbsp;|&nbsp;
        <a href="/accessibility" style="white-space: nowrap;">Accessibility</a>&nbsp;&nbsp;|&nbsp;
        <span style="white-space: nowrap;"><i class="equal-housing-opportunity">&nbsp;</i>Equal Housing Opportunity.</span>
      </div>
    </div>
    <div class="col-2" style="padding: 0; font-size:13px;">
      <a id="agent-access" href="https://mydesk.@(AppConfig.DefaultDomain())" target="_blank" rel="noopener" alt="Agent Access" style="display: inline-block; padding: 0 4px; margin-top: 5px; text-align: center">
        Agent Access
      </a>
    </div>
  </div>
</footer>
