import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MarketInsightsReportComponent } from './market-insights-report/market-insights-report.component';
import { SellerReportComponent } from './seller-report/seller-report.component';

const routes: Routes = [
  { path: 'market-insights-report/:id', component: MarketInsightsReportComponent },
  { path: 'seller-report/:id', component: SellerReportComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChildRoutingModule { }
