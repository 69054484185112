import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonService } from './../common.service';

declare var window: any;

@Component({
  selector: 'app-broker-signature',
  templateUrl: './broker-signature.component.html',
  styleUrls: ['./broker-signature.component.css'],
  providers: [CommonService]
})
export class BrokerSignatureComponent implements OnInit {
  @Input() brokerDetails: any;
  formModal: any;
  contactForm!: FormGroup;
  publicUrl!: string;

  constructor(private fb: FormBuilder, private commonService: CommonService) { }

  ngOnInit(): void {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById('myModal')
    );
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      phone: [''],
      email: ['', [Validators.required, Validators.email]],
      msg: [''],
      sendSms: [false, Validators.requiredTrue]
    });
  }

  openFormModal() {
    this.formModal.show();
  }

  onFormSubmit() {
    this.commonService.getPublicSiteLink().subscribe((data) => {
      this.publicUrl = data.url;
      if (this.publicUrl) {
        this.commonService.createInfoRequest(this.publicUrl, this.contactForm.value)
          .subscribe(data => {
            console.log(data);
            this.formModal.hide();
          })
      }
    })

  }  

}
